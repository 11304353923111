import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import styles from './legal.module.sass'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import LegalNav from '../../components/legal-nav'

const DisclaimersPage = () => (
   <Layout>
      <SEO title="Disclaimers + Disclosures" />
      <div className={['page', styles.page__legal].join(' ')}>
         <LegalNav className={styles.legal__nav}></LegalNav>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Disclaimers + Disclosures
            </h1>
         </div>
         <div className={styles.legal__content}>
            <p>
               Investment advisory services offered through SPC Financial
               <sup>&reg;</sup> (SPC). SPC and Sella &amp; Martinic, LLC (S&M)
               are not registered broker-dealers.
            </p>

            <a id="tax" className={styles.anchor}></a>
            <p>
               Registration with the SEC as an investment adviser does not imply
               a certain level of skill training or endorsement by the SEC.
            </p>
            <p>
               *SPC does not provide tax or legal advice. Tax services and
               analysis are provided by the related firm S&amp;M through a
               separate engagement letter with clients.
            </p>

            <a id="cfp" className={styles.anchor}></a>
            <p>
               Certified Financial Planner Board of Standards Inc. (CFP Board)
               owns the certification marks CFP<sup>&reg;</sup> and CERTIFIED
               FINANCIAL PLANNER&trade; in the U.S., which it authorizes use of
               by individuals who successfully complete CFP Board's initial and
               ongoing certification requirements.
            </p>

            <a id="cima" className={styles.anchor}></a>
            <p>
               Investments & Wealth Institute&trade; (The Institute) is the
               owner of the certification marks "CIMA," and "Certified
               Investment Management Analyst." Use of CIMA, and/or Certified
               Investment Management Analyst signifies that the user has
               successfully completed The Institute's initial and ongoing
               credentialing requirements for investment management
               professionals.
            </p>
            <p>
               Professional Designation Glossary explaining the accrediting
               organizations and requirements for the certifications CFP(r).
               CPA, PFS, CFA, and CLMA can be found <b>here</b>.
            </p>

            <a id="links" className={styles.anchor}></a>
            <p>
               Third-party links are being provided for informational purposes
               only. SPC and S&M are not affiliated with and do not endorse,
               authorize, sponsor, verify, or monitor any of the listed websites
               or their respective sponsors, and they are not responsible or
               liable for the content of any website, or the collection or use
               of information regarding any website's users and/or members.
               Links are believed to be accurate at time of dissemination but we
               make no guarantee, expressed or implied, to the accuracy of the
               links subsequently.
            </p>

            <a id="aum" className={styles.anchor}></a>
            <p>
               <sup>1</sup>
               <b>Assets under advisement and management</b>: accurate as of
               December 31, 2023.
            </p>

            <a id="dual-clients" className={styles.anchor}></a>
            <p>
               <sup>2</sup>
               <b>Percentage of clients of both SPC and S&amp;M</b>: accurate as
               of December 31, 2023.
            </p>

            <p>
               Some icons made by various authors from{' '}
               <OutboundLink
                  href="//flaticon.com"
                  rel="noopener noreferrer"
                  target="_blank">
                  www.flaticon.com
               </OutboundLink>
               .
            </p>
         </div>
      </div>
   </Layout>
)

export default DisclaimersPage
